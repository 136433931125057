<template>
  <i class="material-symbols" :style="{fontSize: `${size}px`}">{{ name }}</i>
</template>

<script>
export default {
  name: "icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 16
    },
  },
};
</script>

<style lang="less" scoped>
.material-symbols {
  font-style: normal;
  color: @black;
  user-select: none;
}
</style>
