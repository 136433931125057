<template>
  <div class="switch" :class="{ active: filteredValue }" @click="toggleActive">
    <div class="toggle-button">
      <Icon :name="icon" class="icon" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "switch-component",
  components: { Icon },
  data: () => {
    return {
      icon: "close",
    };
  },
  props: {
    modelValue: {
      type: [Boolean, String],
      default: undefined,
    },
  },
  computed: {
    filteredValue() {
      if (this.modelValue === "Oui" || this.modelValue === true || this.modelValue === undefined) return true;
      return false;
    },
  },
  methods: {
    toggleActive() {
      if (this.modelValue) {
        this.icon = "close";
        this.$emit("update:modelValue", false);
      } else {
        this.icon = "check";
        this.$emit("update:modelValue", true);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@animation-time: 250ms;
@animation-type: ease;
@background-color: @green;

.switch {
  width: 52px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #79747e;
  border-radius: 32px;
  cursor: pointer;

  transition: background-color @animation-time @animation-type,
    border-color @animation-time @animation-type;

  .toggle-button {
    border-radius: 50%;
    background-color: #79747e;
    width: 24px;
    height: 24px;
    position: relative;
    top: 2px;
    left: 3px;

    transition: transform @animation-time @animation-type,
      background-color @animation-time @animation-type;

    .flex-center();

    .icon {
      width: 16px;
      color: @white;
    }
  }

  &.active {
    background-color: @background-color;
    border-color: @background-color;

    .toggle-button {
      transform: translateX(19px);
      background-color: @white;

      .icon {
        color: @background-color;
      }
    }
  }
}
</style>
