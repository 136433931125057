<template>
  <div class="checkbox" :class="{ checked: checked }" @click="toggleCheck">
    <div class="overlay"></div>
    <Icon :name="'check'" class="icon" />
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "checkbox-component",
  components: { Icon },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCheck() {
      if (this.checked === true)
        this.$emit("check", false);
      else
        this.$emit("check", true);
    },
  },
};
</script>

<style lang="less" scoped>
@animation-time: 250ms;
@animation-type: ease;
@background-color: @blue;

.checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid #49454f;
  position: relative;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  transition: background-color @animation-time @animation-type,
    border-color @animation-time @animation-type;

  .flex-center();

  .icon {
    color: black;
    opacity: 0;
    color: @white;

    transition: opacity @animation-time @animation-type;
  }

  .overlay {
    position: absolute;
    top: calc(-13px);
    left: calc(-13px);
    width: 40px;
    height: 40px;
    background-color: @black;
    opacity: 0;
    border-radius: 50%;
    pointer-events: none;

    transition: opacity @animation-time @animation-type;
  }

  &:hover {
    .overlay {
      opacity: 0.08;
    }
  }

  &.checked {
    background-color: @background-color;
    border-color: @background-color;

    .icon {
      opacity: 1;
    }
  }
}
</style>
