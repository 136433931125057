<template>
  <div class="button" :class="[type]">
    <GoogleSymbol
      v-if="symbol"
      :size="'18px'"
      :symbol="symbol"
      :hover="false"
      class="symbol"
      :color="'white'"
    />

    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
import GoogleSymbol from "@/components/GoogleSymbol.vue";

export default {
  name: "button-component",
  components: { GoogleSymbol },
  props: {
    label: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "action",
    },
  },
};
</script>

<style lang="less" scoped>
.symbol {
  margin-right: 8px;
  color: @white;
}

.button {
  height: 40px;
  width: auto;
  padding: 0 16px 0 16px;
  user-select: none;
  cursor: pointer;
  border-radius: 100px;
  font-family: @roboto-medium;
  font-weight: 500;
  font-size: 14px;
  .flex-center();

  &.action {
    background-color: @blue;
    color: @white;
  }

  &.cancel {
    background-color: @error-red;
    color: @white;
  }

  &.validate {
    background-color: @green;
    color: @white;
  }
}
</style>